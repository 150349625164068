import React from 'react'
import { Sticky } from 'react-sticky'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Nav from './navigation.js'
import './header.scss'

class Header extends React.Component {
  constructor () {
    super()
    this.state = {
      toggleClosed: true
    }
    this.toggleEvent = this.toggleEvent.bind(this)
  }

  toggleEvent (e) {
    e.preventDefault()
    this.setState({ toggleClosed: !this.state.toggleClosed })
  }

  render () {
    const pathnames = this.props.location.pathname.split('/').filter(path => path.length > 0)
    const map = { project: 'Projects', 'design-process': 'Design process', blog: 'You-et writes' }

    let pathSoFar = '/'
    const breadcrumbElement = pathnames.map((path) => {
      pathSoFar += path + '/'
      const key = `bread-to-${pathSoFar.replace(/\//gi, '')}`
      if (pathSoFar === this.props.location.pathname || pathSoFar === this.props.location.pathname + '/') {
        return (<p className="header__breadcrumb-current" key={key}>{this.props.title}</p>)
      } else if (!map[path]) {
        return (<p key={key}>{map[path] || path}</p>)
      } else {
        return (<Link to={pathSoFar} key={key}><span>{map[path] || path}</span></Link>)
      }
    })

    let imageElement
    let imageMissingClass = 'header--sticky'
    if (this.props.fluid) {
      imageElement = (<div className="header__image">
        <Img fluid={this.props.fluid} alt={this.props.alt || 'header image'}/>
      </div>)
      imageMissingClass = ''
    }

    return (
      <Sticky topOffset={10}>
        {({ style, isSticky }) => (
          <header
            style={{ ...style }}
            className={`header ${isSticky ? 'header--sticky' : ''} ${this.state.toggleClosed ? 'header--closed' : ''} ${imageMissingClass}`}
          >
            <Nav />
            <div className="header__wrapper">
              {imageElement}
              <div className="header__content">
                <a href="#toggle" onClick={this.toggleEvent} className="header__menu-toggle">menu</a>
                <div className="header__breadcrumb">
                  {breadcrumbElement}
                </div>
              </div>
            </div>
          </header>
        )}
      </Sticky>
    )
  }
}

export default Header
